import request from '@/utils/request'

//
export function fence(id) {
  return request({
    url: '/anneng/electron/fence/' + id,
    method: 'get'
  })
}
export function addFence(data) {
  return request({
    url: '/anneng/electron/fence',
    method: 'post',
    data
  })
}
export function delFence(id) {
  return request({
    url: '/anneng/electron/fence/' + id,
    method: 'delete'
  })
}
export function updateFence(data) {
  return request({
    url: '/anneng/electron/fence',
    method: 'put',
    data: data
  })
}

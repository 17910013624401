<template>
  <div id="centerLeft1">
    <div class="top-header">
      <span>{{ peopleInfo?.teamName }}力量编成</span>
    </div>
    <div class="table-list">
      <div v-if="peopleListData?.listMap?.length > 0">
        <div class="table-title">
          现场指挥所（{{ peopleListData.totalNum }}人）
        </div>
        <el-table
          :data="peopleListData.listMap"
          style="width: 100%; margin-bottom: 20px"
          stripe
          ref="zhihuisuo"
          :show-header="false"
          :header-cell-style="{
            background: '#3c598c',
            color: '#AFBED8'
          }">
          <el-table-column prop="cadreName" label="" width="120">
          </el-table-column>
          <el-table-column label="" prop="name"> </el-table-column>
        </el-table>
      </div>

      <div v-for="item in chuzhizuData" :key="item.orgName">
        <div class="table-title">
          {{ item.orgName }}
        </div>
        <el-table
          :data="item.listMap"
          style="width: 100%; margin-bottom: 20px"
          stripe
          :show-header="false"
          :header-cell-style="{
            background: '#3c598c',
            color: '#AFBED8'
          }">
          <el-table-column
            v-for="(column, columnIndex) in item.columns"
            :key="columnIndex"
            :label="column.label"
            :prop="column.prop"
            :width="columnIndex == 0 ? '120' : ''">
            <template slot-scope="scope">
              <div v-if="columnIndex == 0">
                {{ scope.row.cadreName }}
              </div>
              <div v-else-if="columnIndex == 1">
                <div v-for="(it, index) in scope.row.name" :key="index">
                  {{ it }}<br />
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="btns">
      <div
        class="btn"
        @click="changeBtn(index)"
        v-for="(item, index) in btnList"
        :key="item.name">
        {{ item.name }}
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="92%"
      @close="dialogVisibleClose"
      custom-class="dialog-list"
      append-to-body>
      <div class="dia-body">
        <el-form
          :model="queryParams"
          ref="queryForm"
          size="small"
          :inline="true"
          label-width="68px">
          <el-form-item label="人员姓名" prop="userName" v-if="indexBtn == 0">
            <el-input
              v-model="queryParams.userName"
              placeholder="请输入人员姓名"
              clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item
            label="装备名称"
            prop="equipmentName"
            v-else-if="indexBtn == 1">
            <el-input
              v-model="queryParams.equipmentName"
              placeholder="请输入装备名称"
              clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item
            label="物资名称"
            prop="materialName"
            v-else-if="indexBtn == 2">
            <el-input
              v-model="queryParams.materialName"
              placeholder="请输入物资名称"
              clearable
              @keyup.enter.native="handleQuery" />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-refresh"
              size="mini"
              @click="resetQuery"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          v-if="indexBtn == 0"
          :data="diaLogTableData"
          style="width: 100%"
          stripe
          height="475"
          :header-cell-style="{
            background: '#3c598c',
            color: 'rgb(220, 231, 255);'
          }">
          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="userName" label="姓名"> </el-table-column>
          <el-table-column prop="userUnit" label="所在单位"> </el-table-column>
          <el-table-column prop="userJob" label="职务/工种"> </el-table-column>
          <el-table-column prop="userPolitics" label="政治面貌">
          </el-table-column>
          <el-table-column prop="userBloodtype" label="血型"> </el-table-column>
          <el-table-column prop="userPhone" label="联系方式"> </el-table-column>
          <el-table-column prop="userGroup" label="所在编组"> </el-table-column>
        </el-table>
        <el-table
          v-else-if="indexBtn == 1"
          :data="diaLogTableData"
          style="width: 100%"
          stripe
          height="475"
          :header-cell-style="{
            background: '#3c598c',
            color: 'rgb(220, 231, 255);'
          }">
          <!-- {
            "teamId": 103,
            "equipmentId": 110221,
            "equipmentName": "通信指挥车",
            "equipmentModel": "富景智慧",
            "positionDeviceCode": "10448000023",
            "smallType": null,
            "mediumType": null,
            "largeType": null,
            "createTime": null,
            "equipmentGroup": "现场处置组",
            "equipmentCaptain": null,
            "equipDate": "2021-06-30",
            "equipmentFactoryNo": null
        }, -->

          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="equipmentName" label="装备名称">
          </el-table-column>
          <el-table-column prop="equipmentModel" label="设备型号">
          </el-table-column>
          <el-table-column prop="equipmentFactoryNo" label="出厂编号">
          </el-table-column>
          <el-table-column prop="equipDate" label="装配日期"> </el-table-column>
          <el-table-column prop="equipmentCaptain" label="机长">
          </el-table-column>
          <el-table-column prop="equipmentGroup" label="所在编组">
          </el-table-column>
        </el-table>
        <el-table
          v-else-if="indexBtn == 2"
          :data="diaLogTableData"
          style="width: 100%"
          stripe
          height="475"
          :header-cell-style="{
            background: '#3c598c',
            color: 'rgb(220, 231, 255);'
          }">
          <!-- { "teamId": 103, "materialId": 2222, "materialName": "润滑油",
          "materialModel": "美孚", "carryNumber": "100", "consumeNumber": "20",
          "supplyNumber": "30", "extantNumber": "110", "position": "华容县",
          "administrator": "张某", "measureUnit": "升", "createTime": null } -->

          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="materialName" label="名称"> </el-table-column>
          <el-table-column prop="materialModel" label="型号"> </el-table-column>
          <el-table-column prop="carryNumber" label="携带量"> </el-table-column>
          <el-table-column prop="consumeNumber" label="消耗量">
          </el-table-column>
          <el-table-column prop="supplyNumber" label="补充量">
          </el-table-column>
          <el-table-column prop="extantNumber" label="现存量">
          </el-table-column>
          <el-table-column prop="position" label="所在位置"> </el-table-column>
          <el-table-column prop="administrator" label="管理员">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { rescueUserDetailInfo, teamEquipments, teamMaterials } from '@/api/test'

export default {
  data() {
    return {
      queryParams: {
        teamId: null,
        userName: null,
        equipmentName: null,
        materialName: null
      },
      dialogTitle: null,
      diaLogTableData: [],
      dialogVisible: false,
      btnList: [
        { name: '人员详情' },
        { name: '装备详情' },
        { name: '物资详情' }
      ],
      indexBtn: 0
    }
  },
  props: {
    peopleListData: {
      type: Object,
      default: () => {}
    },
    chuzhizuData: {
      type: Array,
      default: () => []
    },
    peopleInfo: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    console.log(this.chuzhizuData)
    this.tableHeight()
  },
  methods: {
    tableHeight() {
      this.$nextTick(() => {
        let a = document.querySelectorAll('.el-table__cell')
        let b = document.querySelectorAll('.table-title')
        a.forEach((e) => {
          let w = (840 - b.length * 60) / (a.length / 2)
          e.style.height = `${w}px`
        })
      })
    },
    // 弹窗
    changeBtn(index) {
      this.indexBtn = index
      this.dialogVisible = true
      if (index == 0) {
        this.dialogTitle = '人员详情'
        this.getRescueUserDetailInfo()
      } else if (index == 1) {
        this.dialogTitle = '装备详情'
        this.getTeamEquipments()
      } else {
        this.dialogTitle = '物资详情'
        this.getTeamMaterials()
      }
    },
    // 搜索
    handleQuery() {
      if (this.indexBtn == 0) {
        this.getRescueUserDetailInfo()
      } else if (this.indexBtn == 1) {
        this.getTeamEquipments()
      } else {
        this.getTeamMaterials()
      }
    },
    // 关闭

    dialogVisibleClose() {
      this.reset()
      this.dialogVisible = false
    },
    reset() {
      this.queryParams = {
        teamId: null,
        userName: null,
        equipmentName: null,
        materialName: null
      }
    },
    resetQuery() {
      this.reset()
      if (this.indexBtn == 0) {
        this.getRescueUserDetailInfo()
      } else if (this.indexBtn == 1) {
        this.getTeamEquipments()
      } else {
        this.getTeamMaterials()
      }
    },
    // 人员详情
    getRescueUserDetailInfo() {
      this.queryParams.teamId = this.peopleInfo.id
      rescueUserDetailInfo(this.queryParams).then((res) => {
        if (res.data.data) {
          this.diaLogTableData = res.data.data
        }
      })
    },
    // 装备详情
    getTeamEquipments() {
      this.queryParams.teamId = this.peopleInfo.id
      teamEquipments(this.queryParams).then((res) => {
        if (res.data.data) {
          this.diaLogTableData = res.data.data
        }
      })
    },
    // 装备详情
    getTeamMaterials() {
      this.queryParams.teamId = this.peopleInfo.id
      teamMaterials(this.queryParams).then((res) => {
        if (res.data.data) {
          this.diaLogTableData = res.data.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 453px;
$box-height: 950px;

#centerLeft1 {
  height: $box-height;
  width: $box-width;
  .top-header {
    position: relative;
    display: flex;
    width: 450px;
    height: 48px;
    background-image: url('../../assets/images/H2_002.png');
    background-size: 100% 100%;
    font-size: 20px;
    padding-left: 25px;
    padding-top: 9px;
    margin-bottom: 15px;
    & > span {
      background-image: -webkit-linear-gradient(bottom, #f9fbfe, #afe4fe);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 22px;
      font-weight: 600;
      margin-left: 11px;
    }
  }
  .btns {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
    .btn {
      background-image: url('../../assets/images/a5.svg');
      // background-color: #0e244c;
      color: #c9dff7;
      width: 140px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      // border: 1px solid #4991d1;
    }
    .btn:hover {
      // background-image: linear-gradient(0deg, #1d4c9a, #143772);
      background-image: url('../../assets/images/a4.svg');

      color: #c4d7ee;
    }
  }
  .table-list {
    margin-top: 20px;
    height: 815px;
    overflow: auto;
  }
}
// .table-list
.table-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgb(183, 210, 241);
}
.table-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}
::v-deep.table-list .el-table {
  .cell {
    font-size: 16px;
  }
}
::v-deep .el-table .el-table__cell {
  padding: 9px 0 !important;
}
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  color: rgb(183, 210, 241);
  background-color: transparent;
}
::v-deep .el-table tr {
  background-color: #131e34;
  // background-color: red;
}
// 隔行变色
::v-deep .el-table--striped {
  .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #0d172a;
    // background-color: red;
  }
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #243a64;
}
// hover
::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: unset;
}
::v-deep.el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgb(183, 210, 241);
}
::v-deep.el-table--scrollable-y
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00aaff;
}

.table-title {
  // margin-left: 8px;
  // display: flex;
  font-weight: 600;
  font-size: 18px;
  background-image: url('../../assets/images/a3.svg');
  background-size: 100% 100%;
  margin-bottom: 10px;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
  width: 445px;
  border-radius: 4px;
  color: #c9dff7;
  padding-left: 28px;
  // background-image: -webkit-linear-gradient(bottom, #f9fbfe, #afe4fe);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-color: #1796eb;
  // background-color: #13478e;
  font-size: 18px;
  // color: #63acf9;
  // background-color: yellow;
}
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #2e4c85;
}

::v-deep .dialog-list {
  background-color: #0e2241;
  width: 75% !important;
  height: 694px !important;
  margin-top: 10vh !important;
  .el-dialog__title {
    color: #fff;
  }
  .el-dialog__header {
    padding: 0 0 0 58px;
    height: 50px;
    line-height: 50px;

    background-image: url('../../assets/images/list-top.svg');
    background-repeat: no-repeat;
    background-position: 20px 10px;
    // background-size: 100% 100%;
    background-color: #2a4e93;
  }
  .el-dialog__body {
    padding: 20px 38px;
    height: calc(100% - 55px);
    overflow: auto;
    .el-form-item--small .el-form-item__label {
      color: rgb(183, 210, 241);
    }
    .el-table .el-table__cell.gutter {
      background: #3c598c !important;
    }
  }
}
</style>
